<template>
  <div class="flex flex-col gap-16 items-center align-baseline">
    <div>
      <div class="flex gap-64">
        <div class="flex gap-8">
          <SparkSelect
            v-model="selectedMaterial"
            custom="!w-[300px] !mx-auto p-0"
            label="Material"
            :options="materialOptions"
            name="selection"
          />
          <SparkSelect
            v-model="selectedPrcAcronym"
            custom="!w-[300px] !mx-auto"
            label="Technology"
            :options="prcAcronymOptions"
            name="selection"
          />
          <SparkButton
            class="self-center mt-12 w-200 items-center"
            small
            variant="secondary"
            :disabled="requestRunning"
            @click="sendData"
          >
            <div>
              <img v-if="requestRunning" src="@/assets/img/loading.gif" class="w-20 h-20 ml-auto mr-auto" />
              <p v-else>Update Prices</p>
            </div>
          </SparkButton>
        </div>
        <SparkButton class="self-center mt-12" variant="secondary" small @click="triggerRFQViaMail()"
          ><i class="fa-regular fa-paper-plane pr-8" />Send RFQ</SparkButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';

import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'PartActionButtons',

  components: { SparkButton, SparkSelect },

  data() {
    return {
      prcAcronymOptions: [],
      selectedPrcAcronym: '',
      materialOptions: [],
      selectedMaterial: '',
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),
    ...mapState('supplier', ['selectedFilters', 'requestRunning']),
  },

  watch: {
    selectedFilters: {
      handler(newVal) {
        this.selectedMaterial = newVal.mat_id;
        this.selectedPrcAcronym = newVal.prc_acronym;
      },
    },
  },

  mounted() {
    this.getInitialData();
    this.fetchMaterials();
    this.fetchPrcAcronyms();
  },

  methods: {
    ...mapMutations(['sendTriggerRFQ']),
    ...mapMutations('supplier', ['setSelectedFilters']),
    ...mapActions('supplier', ['requestPriceData', 'getInstantPriceData']),

    triggerRFQViaMail() {
      this.sendTriggerRFQ();
    },

    async fetchMaterials() {
      const prefiltered = true; // prefilter by material level 2
      const distinct = true; // distinct mat_names
      await this.axiosInstance
        .get(`api/v1/default-materials/?prefiltered=${prefiltered}&distinct=${distinct}`)
        .then(response => {
          this.materialOptions = response.data.map(entry => ({
            value: entry.mat_id,
            label: entry.mat_name,
          }));
          this.materialOptions.unshift({ label: ' ', value: null }); // No Material
        })
        .catch(error => {
          console.log(error);
        });
    },

    async fetchPrcAcronyms() {
      const prefiltered = true; // prefilter by template prc
      await this.axiosInstance
        .get(`api/v1/supplier-prc-acronyms/?prefiltered=${prefiltered}`)
        .then(response => {
          this.prcAcronymOptions = response.data.map(entry => ({
            value: entry.prc_acronym,
            label: entry.prc_name,
          }));
          this.prcAcronymOptions.unshift({ label: ' ', value: null }); // No Process
        })
        .catch(error => {
          console.log(error);
        });
    },

    async getInitialData() {
      let partId = this.part.part_id;
      if (!partId) {
        partId = this.$route.params.part_id;
      }
      this.getInstantPriceData({ partId: partId });
    },

    async sendData() {
      if (!this.requestRunning) {
        const data = {
          mat_id: this.selectedMaterial,
          prc_acronym: this.selectedPrcAcronym,
        };

        this.setSelectedFilters({
          mat_id: this.selectedMaterial,
          prc_acronym: this.selectedPrcAcronym,
        });

        this.requestPriceData({ partId: this.part.part_id, data });
      }
    },
  },
};
</script>
