<template>
  <div>
    <label class="text-13 block text-gray-500">Created Date</label>
    <Datepicker
      v-model="dateValue"
      :enable-time-picker="false"
      format="dd.MM.y"
      auto-apply
      text-input
      :text-input-options="textInputOptions"
      :model-value="dateValue"
      class="created-date-picker"
      @update:model-value="handleDateChange"
    />
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'CreatedDate',

  components: {
    Datepicker,
  },

  emits: ['created-date-changed'],

  setup() {
    const textInputOptions = {
      format: 'dd.MM.yyyy',
      enterSubmit: true,
    };

    return {
      textInputOptions,
    };
  },

  data() {
    return {
      dateValue: this.$dayjs(),
    };
  },

  computed: {
    ...mapState(['part']),
  },

  watch: {
    'part.created_date': {
      handler(value) {
        if (value) {
          // Check if value matches dd.mm.yy format
          if (/^\d{2}\.\d{2}\.\d{2}$/.test(value)) {
            // Parse date in dd.mm.yy format
            value = this.$dayjs(value, 'DD.MM.YY').format();
          } else {
            value = this.$dayjs(value);
          }
        }
        this.dateValue = value;
      },

      immediate: true,
    },
  },

  mounted() {
    this.$dayjs.extend(customParseFormat);
    if (this.part?.created_date) {
      this.dateValue = this.$dayjs(this.part.created_date);
    }
  },

  methods: {
    ...mapMutations(['updatePart']),

    handleDateChange(value) {
      console.log('handleDateChange', value);
      if (!value) return;
      this.$emit('created-date-changed', { created: this.$dayjs(value).utc().format() });
      this.updatePart({ created_date: this.$dayjs(value).utc().format() });
    },
  },
};
</script>

<style scoped>
:deep(.created-date-picker input) {
  @apply block border h-32 focus:border-2 px-12 pb-2 pt-4 w-full text-15 text-gray-900 bg-white rounded-4 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600;
}

/* Center the calendar icon */
:deep(.created-date-picker .dp__input_icon) {
  display: none;
}

:deep(.created-date-picker .dp__clear_icon) {
  display: none;
}
</style>
