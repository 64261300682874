<template>
  <div class="relative text-15">
    <div
      class="flex justify-between items-center p-8 rounded-8 border border-gray-300 cursor-pointer"
      @click="toggleDropdown"
    >
      <span class="text-15 px-8">Processes </span>
      <span class="text-15 px-8 text-primary-500"> {{ selectedProcessesText }} </span>
      <i class="fas fa-chevron-down" />
    </div>
    <div
      v-if="isOpen"
      class="absolute w-[300px] top-full rounded-8 right-0 bg-white border border-gray-100 shadow-md z-10"
    >
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search..."
        class="w-full border-none focus:outline-none focus:ring-0 focus:border-gray-300"
        @input="filterProcesses"
      />
      <div class="max-h-[500px] overflow-y-auto border-t border-gray-100">
        <div
          v-for="process in filteredAndOrderedProcesses"
          :key="process[1]"
          class="flex items-center px-8 py-4 hover:bg-gray-50 hover:cursor-pointer"
          @click="selectProcess(process[0])"
        >
          <input
            :id="process.key"
            v-model="selectedProcesses"
            type="checkbox"
            :value="process[0]"
            class="mr-2 rounded-4 border border-gray-300 text-primary-500 focus:ring-primary-600 focus:ring-offset-0"
          />
          <div class="flex items-center p-2">{{ process[1].text }}</div>
          <div class="flex items-center px-4 py-2 bg-gray-50 rounded-4">
            {{ countOfAvailableSteps(process[1].value) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Object.keys(selectedProcesses).length > 0"
      class="absolute right-0 mb-2 hover:cursor-pointer hover:text-primary-500 text-11"
      @click="clearSelectedProcesses"
    >
      <span class="mb-4">Clear</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProcessFilter',

  props: {
    processSteps: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['update-selected-processes'],

  data() {
    return {
      isOpen: false,
      searchQuery: '',
      selectedProcesses: [],
    };
  },

  computed: {
    ...mapState(['processes']),

    selectedProcessesText() {
      if (this.selectedProcesses.length === 0) {
        return '';
      } else if (this.selectedProcesses.length === 1) {
        return '|  ' + this.processes[this.selectedProcesses[0]].text;
      } else {
        const firstProcess = this.processes[this.selectedProcesses[0]].text;
        const remainingCount = this.selectedProcesses.length - 1;
        return '|  ' + `${firstProcess}, +${remainingCount} more`;
      }
    },

    filteredAndOrderedProcesses() {
      const query = this.searchQuery.toLowerCase();
      const filteredProcesses = Object.entries(this.processes).filter(([, value]) => {
        return value.text.toLowerCase().includes(query) || value.value.toLowerCase().includes(query);
      });
      let result = this.orderProcesses(Object.fromEntries(filteredProcesses));
      return result;
    },
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    orderProcesses(processes) {
      let sortedEntries = [];
      if (processes != undefined) {
        sortedEntries = Object.entries(processes).sort(([, value1], [, value2]) => {
          const name1 = value1.text.toLowerCase();
          const name2 = value2.text.toLowerCase();
          return name1.localeCompare(name2);
        });
      }
      return sortedEntries;
    },

    selectProcess(process) {
      const index = this.selectedProcesses.indexOf(process);
      if (index > -1) {
        this.selectedProcesses.splice(index, 1);
      } else {
        this.selectedProcesses.push(process);
      }

      this.$emit('update-selected-processes', this.selectedProcesses);
      this.isOpen = false;
      this.searchQuery = '';
    },

    countOfAvailableSteps(prc_acronym) {
      let processStepCopy = JSON.parse(JSON.stringify(this.processSteps));
      const filteredKeys = Object.keys(processStepCopy).filter(
        key => processStepCopy[key]['prc_acronym'] === prc_acronym
      );
      return Object.keys(filteredKeys).length;
    },

    clearSelectedProcesses() {
      this.selectedProcesses = [];
      this.$emit('update-selected-processes', this.selectedProcesses);
      this.isOpen = false;
      this.searchQuery = '';
    },
  },
};
</script>
