<template>
  <div class="z-0">
    <DropdownMenu
      :list-options="options"
      close-click-style="always"
      :show-caret="false"
      custom-class="p-12"
      custom
      @update-option="choose($event, chain.process_chain_id)"
    >
      <SparkButtonIcon custom="text-gray-500" icon="fas fa-ellipsis" />
    </DropdownMenu>
    <SettingsModal :chain="chain" :show="showSettingsModal" @close="closeSettingsModal()" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import SparkButtonIcon from '../../../../SparkComponents/SparkButtonIcon.vue';

import SettingsModal from './components/SettingsModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import { analysisFinished, computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'ChainMenu',

  components: {
    DropdownMenu,
    SparkButtonIcon,
    SettingsModal,
  },

  props: {
    chain: { type: Object, required: true },
  },

  data() {
    return {
      sortedTemplatesAndProfiles: [],
      showSettingsModal: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'popup', 'analysisProfiles', 'organization']),
    ...mapState('application', ['axiosInstance', 'showSidebar']),
    ...mapGetters(['managerOrPartByUser']),

    username() {
      return this.user.username;
    },

    restrictions() {
      return {
        0: { key: 0, value: 'owner', text: 'Owner' },
        1: { key: 1, value: 'organization', text: 'My Organization' },
      };
    },

    computationRunning() {
      return computationRunning(this.chain.process_chain_id);
    },

    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    primaryProcessChain() {
      return this.chain.process_chain_id === this.part.primary_process_chain_id;
    },

    benchmarkProcessChain() {
      return this.chain.process_chain_id === this.part.benchmark_process_chain_id;
    },

    options() {
      if (this.managerOrPartByUser) {
        let optionsArray = [
          { text: 'Settings', value: 'settings', icon: 'fas fa-cog', disabled: false },
          {
            text: 'Re-Analyze',
            value: 'reanalyze',
            icon: 'fas fa-redo',
            disabled: (this.computationRunning || !this.analysisFinished) ?? false,
          },
          { text: 'Delete', value: 'delete', icon: 'fas fa-trash', disabled: false },
          { text: 'Duplicate', value: 'duplicate', icon: 'fas fa-clone', disabled: false },
          { text: 'Save as Template', value: 'saveTemplate', icon: 'fas fa-bookmark', disabled: false },
          { text: 'Add to Profile', value: 'saveProfile', icon: 'fas fa-plus', disabled: false },
        ];

        if (this.primaryProcessChain) {
          optionsArray.push({
            text: 'Set to Main',
            value: 'main',
            icon: 'fa-solid fa-chart-simple',
            disabled: true,
          });
          optionsArray.push({
            text: 'Set to Benchmark',
            value: 'benchmark',
            icon: 'fa-solid fa-chart-simple',
            disabled: true,
          });
        } else if (this.benchmarkProcessChain) {
          optionsArray.push({
            text: 'Set to Main',
            value: 'main',
            icon: 'fa-solid fa-chart-simple',
            disabled: true,
          });
          optionsArray.push({
            text: 'Set to Benchmark',
            value: 'benchmark',
            icon: 'fa-solid fa-chart-simple',
            disabled: true,
          });
        } else {
          optionsArray.push({
            text: 'Set to Main',
            value: 'main',
            icon: 'fa-solid fa-chart-simple',
            disabled: false,
          });
          optionsArray.push({
            text: 'Set to Benchmark',
            value: 'benchmark',
            icon: 'fa-solid fa-chart-simple',
            disabled: false,
          });
        }

        return optionsArray;
      } else {
        return [{ text: 'Save Process as Template', value: 'saveTemplate', icon: 'fas fa-bookmark', disabled: false }];
      }
    },

    lotOnlyUser() {
      if (this.chain.lot_only == true) {
        return 'Lot Only';
      } else {
        return 'Shared';
      }
    },

    energyConfigs() {
      return this.organization?.energy_configs;
    },

    userEnergyConfig() {
      if (this.energyConfigs != null) {
        return this.energyConfigs[this.chain.energy_config_id].name;
      }
      return null;
    },

    costingConfigs() {
      return this.organization?.costing_configs;
    },

    userCostingConfig() {
      if (this.costingConfigs != null) {
        return this.costingConfigs[this.chain.costing_config_id].name;
      }
      return null;
    },

    templateInformation() {
      let listOfProcessSteps = Object.keys(this.chain.process_steps).map(step => this.chain.process_steps[step].name);

      return [
        ['input', { name: 'Name', value: '' }],
        ['select', { name: 'Access', options: this.restrictions, value: this.restrictions[0].value }],
        ['Owner', this.username],
        ['Process', this.chain.prc],
        ['Machine', this.chain.machine_name],
        ['Material', this.chain.material_name],
        ['Calculation Style', this.chain.calc_style],
        ['Process Steps', listOfProcessSteps],
        ['UID', this.chain.process_chain_id],
        ['Optimization Style', this.chain.opt_style],
        ['Nesting Dimension', this.chain.nst_dim + 'D'],
        ['Shared Build Jobs', this.lotOnlyUser],
        ['Energy Configuration', this.userEnergyConfig],
        ['Costing Configuration', this.userCostingConfig],
        ['Margin', this.chain.margin_user_value * 100 + ' %'],
      ];
    },

    benchmark_process_chain_id() {
      return this.part?.benchmark_process_chain_id;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name + 'saveTemplate' && popup?.clicked == 'ok') {
        let name = this.popup.tableInput[0][1].value;
        let uid = this.popup.tableInput[8][1]; // [7][1]?
        this.saveAsTemplate(name, uid, this.popup.tableInput[1][1].value);
        // empty the name input
        this.popup.tableInput[0][1].value = '';
        this.triggerPopup('');
      } else if (popup?.key == this.$options.name + 'saveProfile' && popup?.clicked == 'ok') {
        this.addToProfile(popup.formData, popup.additionalData);
        this.triggerPopup('');
      } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
        this.triggerPopup('');
      }
    },
  },

  mounted() {
    this.getListProfiles();
    this.getListProfiles();
  },

  methods: {
    ...mapMutations([
      'triggerPopup',
      'removeProcessChain',
      'changeProcessChainUids',
      'updateSingleProcessChain',
      'updateTemplatesAndProfiles',
      'changeProcessChainUids',
      'updatePart',
      'changeInvestigationDetails',
    ]),

    ...mapActions(['fetchAnalysisTemplatesAndProfiles']),

    ...mapMutations('application', ['toggleSidebar']),
    analyzeSingleChain() {
      if (!this.computationRunning) {
        if (!this.computationRunning) {
          this.axiosInstance.post(`api/v1/analysis/process-chain/${this.chain.process_chain_id}/`).then(() => {
            this.$root.notify('success', 'Analysis started', 'Single process chain will be analyzed', 6000);
          });
        }
      }
    },

    choose(event, uid) {
      if (event.value == 'delete') {
        if (this.showSidebar) {
          this.toggleSidebar();
        }
        this.axiosInstance
          .delete(`api/v1/process-chain/${uid}/`)
          .then(response => {
            this.$root.notify('success', response.data.message, 3000);
            this.removeProcessChain(uid);
            if (uid == this.benchmark_process_chain_id) {
              this.changeProcessChainUids({ benchmark_process_chain_id: '' });
            }
          })
          .catch(error => {
            if (error.response) {
              this.$root.notify('warning', error.response.data.error_message, 3000);
            }
          });
      } else if (event.value == 'saveTemplate') {
        this.triggerPopup({
          key: this.$options.name + event.value,
          show: true,
          message: '',
          title: 'Save as Template',
          tableInput: this.templateInformation,
          type: 'table',
          buttons: true,
          buttonContent: [
            { text: 'Cancel', type: 'outlined', value: 'cancel' },
            { text: 'Save', type: 'secondary', value: 'ok' },
          ],
        });
      } else if (event.value == 'saveProfile') {
        this.sortedTemplatesAndProfiles = [];
        this.getListProfiles();
        this.triggerPopup({
          key: this.$options.name + 'saveProfile',
          show: true,
          message: '',
          title: 'Add to Profile',
          type: 'single',
          selectLabel: 'Select a Profile',
          selectOptions: this.sortedTemplatesAndProfiles,
          additionalData: this.chain.process_chain_id,
          buttons: true,
          buttonContent: [
            { text: 'Cancel', type: 'outlined', value: 'cancel' },
            { text: 'Save', type: 'secondary', value: 'ok' },
          ],
        });
      } else if (event.value == 'duplicate') {
        // this is the information which should be included to create the processchain
        this.duplicateProcessChain();
      } else if (event.value == 'reanalyze') {
        this.analyzeSingleChain();
      } else if (event.value === 'main') {
        this.setToMain();
      } else if (event.value === 'benchmark') {
        this.setToBenchmark();
      } else if (event.value === 'settings') {
        this.showSettingsModal = true;
        this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
      }
    },

    setToMain() {
      let formData = {};
      formData['primary_process_chain_id'] = this.chain.process_chain_id;
      if (this.benchmarkProcessChain) {
        formData['benchmark_process_chain_id'] = '';
      }
      this.save(formData);
    },

    setToBenchmark() {
      let formData = {};
      formData['benchmark_process_chain_id'] = this.chain.process_chain_id;
      if (this.primaryProcessChain) {
        formData['primary_process_chain_id'] = '';
      }
      this.save(formData);
    },

    save(formData) {
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, formData).then(response => {
        this.changeProcessChainUids(formData);
        this.updatePart(response.data.part);
      });
    },

    duplicateProcessChain() {
      // api call here
      this.axiosInstance.post(`api/v1/process-chain-duplication/${this.chain.process_chain_id}/`).then(response => {
        this.$root.notify(
          'success',
          'Process Chain Duplicated',
          'This Process was duplicated and saved as new one',
          3000
        );
        this.updateSingleProcessChain(response.data);
      });
    },

    async saveAsTemplate(name, uid, restriction = 'owner') {
      let formData = {
        process_chain_id: uid,
        custom_process_chain_name: name,
        restriction: restriction,
      };
      this.axiosInstance.post('api/v1/template-from-process-chain/', formData).then(() => {
        this.$root.notify('success', 'Template saved', `Template '${name}' successfully created.`, 3000);
        this.updateTemplatesAndProfiles(new Date());
        this.fetchAnalysisTemplatesAndProfiles();
      });
    },

    async addToProfile(profile_uid, template_id) {
      let formData = {
        profile_id: profile_uid,
      };

      try {
        await this.axiosInstance.post(`api/v1/analysis-profile-list/${template_id}/`, formData);
        this.$root.notify('success', 'Template added to Profile', 'Template successfully added to Profile.', 3000);
        this.updateTemplatesAndProfiles(new Date());
      } catch (error) {
        console.error(error);
        if (error.response) {
          const { status, data } = error.response;
          let errorMessage = 'Failed to perform the operation';

          if (status === 409) {
            errorMessage = data.detail || 'Template already exists in profile.';
          } else {
            errorMessage = data.detail || 'Failed to perform the operation';
          }

          this.$root.notify('error', 'Error', errorMessage, 3000);
        } else {
          this.$root.notify('error', 'An error occurred', 'Failed to perform the operation', 3000);
        }
      }
    },

    async getListProfiles() {
      Object.keys(this.analysisProfiles).forEach(key => {
        this.sortedTemplatesAndProfiles.push({
          label: this.analysisProfiles[key].name,
          value: this.analysisProfiles[key].uid,
        });
      });
    },

    closeSettingsModal() {
      this.showSettingsModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-toggler {
  position: absolute;
  right: 0;
}
</style>
