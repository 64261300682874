<template>
  <table class="spark-table w-full text-11">
    <tbody>
      <tr>
        <td colspan="4" class="cursor-pointer text-left" @click="showCostBreakdown('pre_process')">
          Pre-Process
          <span v-if="!boolShowEntry['pre_process']" class="dropdown-chevron"><i class="fas fa-chevron-down" /></span>
          <span v-else class="dropdown-chevron"><i class="fas fa-chevron-up" /></span>
        </td>
        <td class="text-right">
          <transition name="value-update" mode="out-in">
            <div
              v-if="
                amCostBreakdown !== undefined &&
                amCostBreakdown.pre_process !== null &&
                amCostBreakdown.pre_process !== undefined
              "
            >
              {{ $formatPrice(amCostBreakdown.pre_process) }}
            </div>
            <div v-else :class="{ pulsing: computationRunning }">...</div>
          </transition>
        </td>
      </tr>
      <transition v-for="(value, key) in chain?.cost_breakdown['pre_process']" :key="key" name="collapsibleopen">
        <tr v-show="boolShowEntry['pre_process']">
          <td colspan="2" />
          <td colspan="2" class="">&nbsp;&nbsp;&nbsp;{{ label[key] }}</td>
          <td class="text-right">
            <transition name="value-update" mode="out-in">
              <div v-if="amCostBreakdown != undefined && amCostBreakdown.pre_process != null">
                {{ $formatPrice(value) }}
              </div>
              <div v-else :class="{ pulsing: computationRunning }">...</div>
            </transition>
          </td>
        </tr>
      </transition>
      <tr>
        <td colspan="4" class="cursor-pointer" @click="showCostBreakdown('in_process')">
          In-Process
          <span v-if="!boolShowEntry['in_process']" class="dropdown-chevron"><i class="fas fa-chevron-down" /></span>
          <span v-else class="dropdown-chevron"><i class="fas fa-chevron-up" /></span>
        </td>
        <td class="text-right">
          <transition name="value-update" mode="out-in">
            <div v-if="amCostBreakdown != undefined && amCostBreakdown.in_process != null">
              {{ $formatPrice(amCostBreakdown.in_process) }}
            </div>
            <div v-else :class="{ pulsing: computationRunning }">...</div>
          </transition>
        </td>
      </tr>
      <transition v-for="(value, key) in chain?.cost_breakdown['in_process']" :key="key" name="collapsibleopen">
        <tr v-show="boolShowEntry['in_process']">
          <td colspan="2" />
          <td colspan="2" class="">&nbsp;&nbsp;&nbsp;{{ label[key] }}</td>
          <td class="text-right">
            <transition name="value-update" mode="out-in">
              <div v-if="amCostBreakdown != undefined && amCostBreakdown.in_process != null">
                {{ $formatPrice(value) }}
              </div>
              <div v-else :class="{ pulsing: computationRunning }">...</div>
            </transition>
          </td>
        </tr>
      </transition>
      <tr>
        <td colspan="4" class="cursor-pointer" @click="showCostBreakdown('post_process')">
          Post-Process
          <span v-if="!boolShowEntry['post_process']" class="dropdown-chevron"><i class="fas fa-chevron-down" /></span>
          <span v-else class="dropdown-chevron"><i class="fas fa-chevron-up" /></span>
        </td>
        <td class="text-right">
          <transition name="value-update" mode="out-in">
            <div
              v-if="
                amCostBreakdown !== undefined &&
                amCostBreakdown.post_process !== null &&
                amCostBreakdown.post_process !== undefined
              "
            >
              {{ $formatPrice(amCostBreakdown.post_process) }}
            </div>
            <div v-else :class="{ pulsing: computationRunning }">...</div>
          </transition>
        </td>
      </tr>
      <transition v-for="(value, key) in chain?.cost_breakdown['post_process']" :key="key" name="collapsibleopen">
        <tr v-show="boolShowEntry['post_process']">
          <td colspan="2" />
          <td colspan="2" class="">&nbsp;&nbsp;&nbsp;{{ label[key] }}</td>
          <td class="text-right">
            <transition name="value-update" mode="out-in">
              <div v-if="amCostBreakdown != undefined && amCostBreakdown.post_process != null">
                {{ $formatPrice(value) }}
              </div>
              <div v-else :class="{ pulsing: computationRunning }">...</div>
            </transition>
          </td>
        </tr>
      </transition>
      <tr>
        <td colspan="4" class="cursor-pointer" @click="showCostBreakdown('material')">
          Material
          <span v-if="!boolShowEntry['material']" class="dropdown-chevron"><i class="fas fa-chevron-down" /></span>
          <span v-else class="dropdown-chevron"><i class="fas fa-chevron-up" /></span>
        </td>
        <td class="text-right">
          <transition name="value-update" mode="out-in">
            <div
              v-if="
                amCostBreakdown !== undefined &&
                amCostBreakdown.material !== null &&
                amCostBreakdown.material !== undefined
              "
            >
              {{ $formatPrice(amCostBreakdown.material) }}
            </div>
            <div v-else :class="{ pulsing: computationRunning }">...</div>
          </transition>
        </td>
      </tr>
      <transition v-for="(value, key) in chain?.cost_breakdown['material']" :key="key" name="collapsibleopen">
        <tr v-show="boolShowEntry['material']">
          <td colspan="2" />
          <td colspan="2" class="">&nbsp;&nbsp;&nbsp;{{ label[key] }}</td>
          <td class="text-right">
            <transition name="value-update" mode="out-in">
              <div v-if="amCostBreakdown != undefined && amCostBreakdown.material != null">
                {{ $formatPrice(value) }}
              </div>
              <div v-else :class="{ pulsing: computationRunning }">...</div>
            </transition>
          </td>
        </tr>
      </transition>
      <tr
        title="Optimal/target manufacturing cost for in-house production. Excludes any administrative cost like order management, profit margins, markups etc."
      >
        <td colspan="4" />
        <td class="font-bold text-right underline">
          {{ $formatPrice(amCostPerPart) }}
        </td>
      </tr>
      <tr />
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';

import { computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'CostTable',

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    label: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      boolShowEntry: {
        pre_process: false,
        in_process: false,
        post_process: false,
        material: false,
      },
    };
  },

  computed: {
    ...mapGetters(['processChainsInitializationFinished']),
    computationRunning() {
      return computationRunning(this.chain.uid);
    },

    boolProcessChainExists() {
      if (this.chain == null) {
        return false;
      }
      if (this.chain.cost != null) {
        return true;
      } else {
        return false;
      }
    },

    amCostPerPart() {
      if (this.boolProcessChainExists) {
        return this.chain.cost.cost_pp;
      } else return undefined;
    },

    amCostBreakdown() {
      if (this.boolProcessChainExists) {
        let obj = this.chain.cost_breakdown;
        let result = {};
        for (const [key, value] of Object.entries(obj)) {
          result[key] = 0;
          for (const [subkey, subvalue] of Object.entries(obj[key])) {
            result[key] += obj[key][subkey];
          }
        }
        return result;
      } else return undefined;
    },
  },

  methods: {
    showCostBreakdown(step) {
      this.boolShowEntry[step] = !this.boolShowEntry[step];
    },
  },
};
</script>
