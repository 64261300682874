<template>
  <div v-if="hasCategories" class="flex flex-wrap items-start">
    <div v-for="(cat, id) in allCategories" :key="id" class="w-full">
      <div v-if="cat.items" class="flex w-full">
        <div class="spark-new-input-group !px-0 w-full">
          <label class="text-13 text-gray-500 bg-white"> {{ cat.label }}<span v-if="cat.required">*</span> </label>
          <select
            v-model="cat.uid"
            class="block mb-8 h-32 px-12 pb-2 pt-4 w-full text-15 text-gray-900 bg-white rounded-4 border-1 border-gray-200 focus:outline-none focus:ring-0 focus:border-primary-600 peer"
            :class="{
              '!border-gray-200 !bg-gray-50 !text-gray-300': lockedForUser || loading,
              '!text-gray-300': !cat.uid,
              '!border-red-500': !cat.uid && cat.required,
            }"
            :title="lockedForUser ? lockedTitle : ''"
            :disabled="lockedForUser || loading"
            @change="saveComboCategory(id, cat)"
          >
            <option :value="null" />
            <option v-for="(item, itemid) in cat.items" :key="itemid" :value="itemid">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div v-else class="flex w-full">
        <div class="spark-new-input-group w-full !px-0">
          <input
            v-model="cat.value"
            type="text"
            name="text_category"
            autocomplete="off"
            class="spark-new-input-clean !ring-0 hover:border-primary-500 focus:border-primary-500 border-gray-100 rounded-4 text-13 mx-0 w-full"
            :title="lockedForUser ? lockedTitle : ''"
            :disabled="lockedForUser || loading"
            :class="!cat.value && cat.required && highlightCategories ? 'highlight-red-border' : ''"
            @change="saveTextCategory(id, cat)"
          />
          <label class="spark-new-input-label crop"> {{ cat.label }}<span v-if="cat.required">*</span></label>
        </div>
        <div v-if="validateThisUrl(cat.value)" title="Go to" class="external-link-icon" @click="goToUrl(cat.value)">
          <i class="fas fa-external-link-alt" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="font-semibold text-11 self-center" v-text="'No details available'" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import validateUrl from '@/composables/validateUrl.js';
export default {
  name: 'PrpCategoryInput',

  props: {
    requiredCategories: { type: Boolean },
    part: { type: Object, default: () => {} },
  },

  data() {
    return {
      comboCategories: {},
      textCategories: {},
      filledCategories: [],
      loading: false,
    };
  },

  computed: {
    ...mapState(['user', 'highlightCategories', 'partLibraryFilters']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    allComboCategories() {
      return this.part?.categories?.combo_categories;
    },

    allCategories() {
      const combo = this.part?.categories?.combo_categories || {};
      const text = this.part?.categories?.text_categories || {};
      const combined = { ...combo, ...text };
      return Object.fromEntries(Object.entries(combined).sort((a, b) => a[1].order_index - b[1].order_index));
    },

    hasCategories() {
      return (
        Object.keys(this.part?.categories?.combo_categories).length > 0 ||
        Object.keys(this.part?.categories?.text_categories).length > 0
      );
    },

    allTextCategories() {
      return this.part?.categories?.text_categories;
    },
  },

  watch: {
    allComboCategories: {
      handler() {
        this.getCategories();
      },

      immediate: true,
    },
  },

  mounted() {
    for (let [key, value] of Object.entries(this.allComboCategories)) {
      if (value.uid != '') {
        this.filledCategories.push(key);
      }
    }
    for (let [key, value] of Object.entries(this.allTextCategories)) {
      if (value.value != '') {
        this.filledCategories.push(key);
      }
    }
  },

  methods: {
    ...mapActions('prp', ['fetchPrpPartLibraryData']),

    validateThisUrl(url) {
      return validateUrl(url).validateBool;
    },

    goToUrl(url) {
      let prefix = 'https://';
      if (!url.includes(prefix) && !url.includes('http://')) {
        url = prefix + url;
      }
      window.open(url, '_blank');
    },

    getCategories() {
      for (let [key, value] of Object.entries(this.allComboCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.comboCategories[key] = value;
      }
      for (let [key, value] of Object.entries(this.allTextCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.textCategories[key] = value;
      }
    },

    saveTextCategory(id, textcat) {
      let cat = {};
      cat[id] = {
        category: textcat.category,
        value: textcat.value,
        required: textcat.required,
      };
      if (textcat.value != '') {
        this.filledCategories.push(id);
      } else {
        this.filledCategories = this.filledCategories.filter(item => item !== id);
      }
      this.updatePartNewPart({ text_categories: cat });
    },

    saveComboCategory(id, combocat) {
      let cat = {};
      if (combocat.uid != '') {
        cat[id] = {
          uid: combocat.uid,
          value: combocat.items[combocat.uid],
        };
      } else {
        cat[id] = {
          uid: '',
          value: '',
        };
      }
      if (combocat.uid != '') {
        this.filledCategories.push(id);
      } else {
        this.filledCategories = this.filledCategories.filter(item => item !== id);
      }
      this.updatePartNewPart({ combo_categories: cat });
    },

    updatePartNewPart(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'combo_categories')) {
        // change data structure for backend
        for (const [key, value] of Object.entries(data['combo_categories'])) {
          data['combo_categories'][key] = value.uid;
        }
      }
      if (this.part.part_id != undefined && this.part.part_id != '' && this.part.part_id != 0) {
        this.savePart(data);
      }
    },

    async savePart(formData) {
      this.loading = true;
      // This function will parse the current 'part' instance to the database.
      await this.axiosInstance
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(async () => {
          let formData = JSON.parse(JSON.stringify(this.partLibraryFilters));
          formData = { ...formData, ...{ page: this.currentPage }, ...{ not_checked_out: true } };
          await this.fetchPrpPartLibraryData(formData);
          this.loading = false;
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>
