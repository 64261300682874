<template>
  <div>
    <div class="flex items-baseline">
      <SparkForm class="w-200 ml-10">
        <SparkInput v-model="inputText" name="input" :label="file.title" :default="file.default" disabled />
      </SparkForm>

      <DownloadFile
        class="text-11 ml-4"
        data-type="Part"
        :part-id="file.part_id"
        :new-file-name="file.basename"
        :disabled="file.basename === undefined"
      />

      <DeleteCad v-if="file.basename" class="text-11 ml-4" :file="file" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkForm from '../../SparkComponents/SparkForm.vue';
import SparkInput from '../../SparkComponents/SparkInput.vue';

import DeleteCad from './DeleteCad.vue';

import DownloadFile from '@/components/Reusable/DownloadFile.vue';

export default {
  name: 'FileRowCad',

  components: {
    DownloadFile,
    DeleteCad,
    SparkForm,
    SparkInput,
  },

  props: {
    file: {
      type: Object,
      default() {
        return {
          title: '',
          default: '',
          type: '',
          part_id: '',
        };
      },
    },
  },

  computed: {
    ...mapState(['part']),

    inputText() {
      if (!this.file.basename) {
        return this.file.default;
      } else {
        return this.file.basename;
      }
    },
  },
};
</script>
