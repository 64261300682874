export function getSupplierState() {
  return {
    isSupplier: false,
    suppliers: [],
    publicSuppliers: [],
    customersSuppliers: [],
    users: [],
    selectedSuppliers: [],
    selectedSalesRep: {},
    selectedFilters: {
      material: '',
      prc: '',
    },
    requestRunning: false,
  };
}
export default getSupplierState();
