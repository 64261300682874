<template>
  <div style="margin: 10px; width: 50%">
    <div class="flex items-center justify-between">
      <p class="font-bold text-15 mr-8">Manufacturing Cost</p>
    </div>

    <table class="spark-table" style="margin-top: 20px">
      <tbody>
        <tr
          title="Optimal/target manufacturing cost for in-house production. Excludes any administrative cost like order management, profit margins, markups etc."
        >
          <td colspan="4">Manufacturing Cost per Part</td>
          <td class="right-aligned">
            <transition name="value-update" mode="out-in">
              {{ $formatPrice(activeProcessChain.cost.cost_pp) }}
            </transition>
          </td>
        </tr>
        <tr>
          <td colspan="4">Manufacturing Cost per Lot</td>
          <td class="right-aligned">
            <transition name="value-update" mode="out-in">
              {{ $formatPrice(activeProcessChain.cost.cost_pl) }}
            </transition>
          </td>
        </tr>
        <tr>
          <td colspan="4">Buy-To-Fly Ratio</td>
          <td class="right-aligned">
            <transition name="value-update" mode="out-in">
              <div>
                {{ activeProcessChain.info['Buy-To-Fly Ratio'] }}
              </div>
            </transition>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MillingManufacturingCost',

  computed: {
    ...mapState(['part', 'investigationDetails']),
    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },
  },
};
</script>

<style lang="scss" scoped></style>
