<template>
  <div class="bg-white ml-10 rounded-8 border border-gray-50 text-13 mb-24">
    <table class="w-full">
      <thead>
        <tr class="text-gray-600 text-13 border-b border-gray-50 align-middle h-48 font-normal">
          <td class="pl-8">
            <span class="cursor-pointer" @click.stop="toggleMasterSelect()">
              <i v-if="allSelected" class="far fa-check-square text-15" />
              <i v-else class="far fa-square text-15" />
            </span>
          </td>
          <td>&nbsp;</td>
          <td>Supplier</td>
          <td>Contact</td>
          <td>Calculation</td>
          <td>Process</td>
          <td class="text-right pr-32">Price</td>
          <td class="w-40">&nbsp;</td>
        </tr>
      </thead>
      <tbody>
        <template v-for="supplier in selectedSuppliers" :key="supplier">
          <template v-for="price in supplier.priceInfo" :key="price">
            <tr class="align-middle h-56 border-b border-gray-50 hover:bg-gray-50">
              <td class="pl-8">
                <ActionCheckbox
                  :checked-suppliers="checkedSuppliers"
                  :supplier-id="supplier.uid"
                  @click.stop="toggleCheckSupplier(supplier.uid)"
                />
              </td>
              <td>
                <img class="supplier-logo" :src="supplier.logoUrl" alt="supplier-logo" />
              </td>
              <td class="font-medium">{{ supplier.name }}</td>
              <td>
                <div class="flex flex-col">
                  <div class="text-gray-1000 font-medium">
                    {{ supplier.sales_contact?.first_name }} {{ supplier.sales_contact?.last_name }}
                  </div>
                  <div class="text-gray-400">{{ supplier.sales_contact?.email }}</div>
                </div>
              </td>
              <td>{{ price.calculation_method }}</td>
              <td>
                <div class="flex flex-col">
                  <div class="text-gray-1000">
                    {{ price.material }}
                  </div>
                  <div class="text-gray-400">{{ price.technology }}</div>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <div
                    v-if="
                      $formatTwoDecimalPlaces(price.target_price_min) ===
                      $formatTwoDecimalPlaces(price.target_price_max)
                    "
                    class="font-normal text-right text-black mr-32"
                  >
                    {{ $formatTwoDecimalPlaces(price.target_price_min) }}<span class="text-gray-400"> €</span>
                  </div>
                  <div v-else class="font-normal text-right text-black mr-32">
                    {{ $formatTwoDecimalPlaces(price.target_price_min) }}<span class="text-gray-400"> €</span> -
                    {{ $formatTwoDecimalPlaces(price.target_price_max) }}<span class="text-gray-400"> €</span>
                  </div>
                  <div class="text-gray-400 text-right mr-32 text-11">
                    {{ $dayjs(price.updated).locale('de').format('DD.MM.YYYY') }}
                  </div>
                </div>
              </td>
              <td class="w-40">
                <SparkButtonIcon icon="fa-regular fa-paper-plane" @click.stop="shareSingleRFQViaMail(supplier.uid)" />
              </td>
            </tr>
          </template>
        </template>

        <tr class="align-middle h-56 border-b border-gray-50 hover:bg-gray-50">
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>Market prices</td>
          <td>-</td>
          <td>AI</td>
          <td>-</td>
          <td class="text-right">
            <ExternalMarketPrice />
          </td>
          <td class="w-40">&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import 'dayjs/locale/de';

import ActionCheckbox from './components/ActionCheckbox.vue';
import ExternalMarketPrice from './components/ExternalMarketPrice.vue';

import SparkButtonIcon from '@/components/SparkComponents/SparkButtonIcon.vue';

export default {
  name: 'SupplierPanelFrame',

  components: {
    SparkButtonIcon,
    ActionCheckbox,
    ExternalMarketPrice,
  },

  data() {
    return {
      allSelected: false,
      checkedSuppliers: {},
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),
    ...mapState(['part', 'triggerRFQ']),
    ...mapState('supplier', ['selectedSuppliers']),
  },

  watch: {
    allParts: {
      handler() {
        const updatedCheckedParts = {};
        for (let partId in this.checkedParts) {
          const part = this.allParts.find(part => part.part_id === partId);
          if (part) {
            updatedCheckedParts[partId] = true;
          }
        }

        setTimeout(() => {
          this.checkedParts = updatedCheckedParts;
        }, 300);
      },
    },

    checkedParts: {
      handler() {
        if (Object.keys(this.checkedParts).length === 0) {
          this.allSelected = false;
        }
      },

      deep: true,
    },

    triggerRFQ(newVal) {
      if (newVal != 0) {
        this.shareRFQViaMail();
      }
    },
  },

  methods: {
    toggleMasterSelect() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.selectedSuppliers.map(supplier => {
          this.checkedSuppliers[supplier.uid] = true;
        });
      } else {
        this.checkedSuppliers = {};
      }
    },

    toggleCheckSupplier(uid) {
      if (uid in this.checkedSuppliers) {
        delete this.checkedSuppliers[uid];
      } else {
        this.checkedSuppliers[uid] = true;
      }
    },

    shareSingleRFQViaMail(uid) {
      this.checkedSuppliers[uid] = true;
      this.shareRFQViaMail();
    },

    async shareRFQViaMail() {
      this.$root.notify('success', 'RFQ Mail', 'Generating content', 3000);

      let formData = { uids: Object.keys(this.checkedSuppliers) };
      this.axiosInstance
        .post('/api/v1/part/' + this.part.part_id + '/mail-request-for-quotation/', formData)
        .then(response => {
          // take MailTo content from response data and put it in a fresh mail
          window.open(response.data);
        })
        .catch(error => {
          this.$root.notifyError('RFQ Mail', error);
        });

      this.checkedSuppliers = {};
      this.allSelected = false;
    },
  },
};
</script>

<style>
.supplier-logo {
  height: 30px;
}
</style>
